import Modal from "../Shared/Modal/index.vue";
import AssignmentFileModal from "./AssignmentFileModal.vue";
import Alertbox from "../../mixins/AlertBox";
import { formatter } from "gurhan/helpers/Date";
import Crpyto from "gurhan/helpers/Crypto";
const crypto = new Crpyto("KEY-OF-FILES");
export default {
  mixins: [Alertbox],
  components: { Modal, AssignmentFileModal },
  methods: {
    formatter,
    FILE_PAGE_URL(item) {
      const urlHash = crypto.crypto(
        JSON.stringify({
          ...item,
          type: this.fileType,
          returnPath: this.$route.fullPath,
        })
      );
      return `/files?hash=${urlHash.replace(/\+/g, "ADMIN")}`;
    },
    async SET_REMOVE_FILE(returnPath = "") {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.delete(
        `Assignments/${this.removeFile.id}`
      );
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        if (returnPath === "") this.files.splice(this.removeFile.index, 1);
        else this.$router.push(returnPath);
        this.removeFile.status = false;
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    REFRESH_COMPONENT(variant, content) {
      this.SET_ALERT_BOX(variant, content);
      this.showNewFileModal = false;
      this.showEditFileModal = false;
      this.SET_FILE_TYPE_BEFORE_GET_ASSIGNMENT();
    },
    SHOW_ASSIGNMENT_DETAIL(item) {
      this.selectedFile = item;
      this.editModalKey += 1;
      this.showEditFileModal = true;
    },
    async GET_ASSIGNMENT_FILES(parentId, type) {
      this.loading = true;
      const response = await this.$api.get(
        `Assignments?parentId=${parentId}&type=${type}`
      );
      if (response.message === "OK") {
        this.files = response.data;
      } else this.files = [];
      this.loading = false;
    },
    SET_FILE_TYPE_BEFORE_GET_ASSIGNMENT() {
      if (this.fileType === 1)
        this.GET_ASSIGNMENT_FILES(this.product.id, this.fileType);
      else if (this.fileType === 2)
        this.GET_ASSIGNMENT_FILES(this.event.id, this.fileType);
      if (this.fileType === 3)
        this.GET_ASSIGNMENT_FILES(this.video.id, this.fileType);
    },
  },
};
