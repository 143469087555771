<template>
  <div class="container">
    <h2 class="mt-4">{{ $t("uploadedItems") }}</h2>
    <div class="d-flex justify-content-end">
      <button @click="showNewFileModal = true" class="btn btn-pill donate my-3">
        {{ $t("newUploadFile") }}
      </button>
    </div>
    <Table no-search :loading="loading" :items="files" :headers="headers">
      <template #status="{ item, index }">
        <router-link class="email-link" :to="FILE_PAGE_URL(item)">
          <button class="btn m-1 btn-pill btn-xs btn-info mx-1">
            {{ $t("fileUploadUsers") }}
          </button>
        </router-link>
        <a target="_blank" class="email-link" :href="$api.image(item.filePath)">
          <button class="btn m-1 btn-pill btn-xs donate mx-1">
            {{ $t("viewFile") }}
          </button>
        </a>
        <button
          :title="$t('update')"
          class="btn m-1 btn-pill btn-xs btn-warning mx-1"
          @click="SHOW_ASSIGNMENT_DETAIL(item)"
        >
          <img src="/images/edit.svg" width="20" alt="" />
        </button>
        <button
          :title="$t('remove')"
          @click="removeFile = { status: true, index, id: item.id }"
          class="btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon"
        >
          <img src="/images/trush.svg" width="20" alt="" />
        </button>
      </template>
      <template #title="{ item }">
        <a target="_blank" class="email-link" :href="$api.image(item.filePath)">
          {{ item.title }}
        </a>
      </template>
      <template #createdAt="{ item }">
        {{ formatter(new Date(item.createdAt)) }}
      </template>
      <template #expiredAt="{ item }">
        {{ fileType === 2 ? formatter(new Date(item.expiredAt)) : "-" }}
      </template>
    </Table>
    <Modal hide-footer lg v-model="showNewFileModal">
      <AssignmentFileModal
        :product="product"
        :event="event"
        :video="video"
        :type="fileType"
        @refresh="REFRESH_COMPONENT"
        @close="showNewFileModal = false"
      ></AssignmentFileModal>
    </Modal>
    <Modal hide-footer lg v-model="showEditFileModal">
      <AssignmentFileModal
        :product="product"
        edit
        :key="editModalKey"
        :event="event"
        :video="video"
        :type="fileType"
        :item="selectedFile"
        @close="showEditFileModal = false"
        @refresh="REFRESH_COMPONENT"
      ></AssignmentFileModal>
    </Modal>
    <Modal
      @ok="SET_REMOVE_FILE()"
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeFile.status"
    >
      {{ $t("confirmDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import Assignments from "./index";

export default {
  mixins: [Assignments],
  components: { Table },
  props: {
    type: {
      default: () => ({ typeTable: 1 }),
    },
    product: {
      default: () => {},
    },
    event: {
      default: () => {},
    },
    video: {
      default: () => {},
    },
  },
  data() {
    return {
      removeFile: {
        status: false,
        id: -1,
        index: -1,
      },
      editModalKey: 1,
      selectedFile: {},
      showNewFileModal: false,
      showEditFileModal: false,
      confirmDeleteModal: false,
      files: [],
      headers: [
        {
          title: this.$t("title"),
          value: "title",
          col: 6,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("expiredAt"),
          value: "expiredAt",
          col: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],
      loading: false,
    };
  },
  computed: {
    fileType() {
      const typeOneArr = [1, 2, 4];
      const typeTwoArr = [3];
      const typeThreeArr = [5];
      if (typeOneArr.includes(this.type.typeTable)) return 1;
      else if (typeTwoArr.includes(this.type.typeTable)) return 2;
      else if (typeThreeArr.includes(this.type.typeTable)) return 3;
      else return 2;
    },
  },
  mounted() {
    this.SET_FILE_TYPE_BEFORE_GET_ASSIGNMENT();
  },
};
</script>

<style></style>
